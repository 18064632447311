<template>
  <div>
    <loading v-if="cargando" />
    <div class="row">
      <div class="col-md-1 mb-2">
        <button
          type="button"
          class="btn btn-primary"
          v-if="path.length == 0"
          @click="addPath()"
        >
          <i class="fas fa-plus"></i>
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-if="path.length > 0 && pathPoli == 0"
          @click="limpiarMapa()"
        >
          <i class="fas fa-trash"></i>
        </button>
        <button
          type="button"
          class="btn btn-success"
          v-if="pathPoli.length > 0"
          @click="savePolilineas()"
        >
          <i class="fas fa-save"></i>
        </button>
      </div>
      <div class="col-md-2 mt-1" v-if="path.length > 0">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            id="accion"
            v-model="accion"
            class="custom-control-input"
            @click="renderizarRuta()"
          />
          <label class="custom-control-label" for="accion"
            >Verificar Ruta</label
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <gmap-map
          :center="center"
          :zoom="15"
          style="width: 100%; height: 600px"
          ref="map"
          @click="addMarkers"
          :options="{
            clickableIcons: false,
          }"
        >
          <gmap-marker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :icon="m.icon"
            :draggable="true"
            @dragend="addMarkers"
          ></gmap-marker>
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="infoWinOpen = false"
          >
            <div class="wrapper">
              <div class="row">
                <div class="col-md-9">
                  <h3 style="font-size: 15px" class="text-center">
                    Velocidad Maxima
                  </h3>
                </div>
                <div class="col-md-3 pr-4">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="addKm()"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Kilometros:</label
                  ><input
                    v-model="form.km_max"
                    class="form-control form-control-sm"
                    type="number"
                    placeholder="Vel. Maxima"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label>Observaciones</label><br /><textarea
                    cols="30"
                    rows="2"
                    class="form-control form-control-sm"
                    v-model="form.observacion"
                  ></textarea>
                </div>
              </div>
            </div>
          </gmap-info-window>
          <gmap-polyline
            v-if="path.length > 0"
            :options="polylineOptions"
            :path="path"
            :editable="!accion"
            @path_changed="updateEdited($event)"
            @dblclick="handleClickForDelete($event)"
            @click="editPolilinea($event)"
            ref="polyline"
          >
          </gmap-polyline>
        </gmap-map>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col">
        <table
          id="geocercasPoli"
          class="table table-bordered table-striped table-hover table-sm"
        >
          <thead>
            <tr>
              <th>Posicion</th>
              <th>Latitud Inicial</th>
              <th>Longitud Inicial</th>
              <th>Latitud Final</th>
              <th>Longitud Final</th>
              <th>Velocidad Maxima (Kms)</th>
              <th>Observacion</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="polilineas in pathPoli"
              :key="polilineas.id"
              :class="activo == polilineas.orden ? 'table-success' : ''"
            >
              <td v-text="polilineas.orden"></td>
              <td v-text="polilineas.lat_ini"></td>
              <td v-text="polilineas.lon_ini"></td>
              <td v-text="polilineas.lat_fin"></td>
              <td v-text="polilineas.lon_fin"></td>
              <td v-text="polilineas.km_max"></td>
              <td v-text="polilineas.observacion"></td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm bg-navy"
                  @click="editPoli(polilineas)"
                >
                  <i class="fas fa-edit"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import axios from "axios";

export default {
  name: "RutaPolilineas",
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        km_max: null,
        observacion: null,
      },
      latitud: null,
      longitud: null,
      center: { lat: 4.651371, lng: -74.071509 },
      markers: [],
      path: [],
      pathPoli: [],
      mvcPath: null,
      errorMessage: null,
      polylineGeojson: "",
      place: "",
      polylineOptions: {
        strokeColor: "#ff1c79",
        strokeOpacity: "0.7",
        strokeWeight: 8,
      },
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
        },
      },
      lineasEliminar: [],
      accion: false,
      edge: null,
      activo: null,
      cargando: false,
    };
  },

  watch: {
    polylinePath: function(path) {
      if (path) {
        this.path = path;
      }
    },
  },

  computed: {
    polylinePath: function() {
      if (!this.mvcPath) return null;

      let path = [];
      for (let j = 0; j < this.mvcPath.getLength(); j++) {
        let point = this.mvcPath.getAt(j);
        path.push({ lat: point.lat(), lng: point.lng() });
      }
      return path;
    },
  },

  methods: {
    init() {
      if (this.$parent.form.ruta_polilineas.length > 0) {
        this.accion = true;
        this.pathPoli = this.$parent.form.ruta_polilineas;

        for (let i = 0; i < this.pathPoli.length; i++) {
          if (i == 0) {
            this.path.push({
              lat: parseFloat(this.pathPoli[i].lat_ini),
              lng: parseFloat(this.pathPoli[i].lon_ini),
            });
          }
          this.path.push({
            lat: parseFloat(this.pathPoli[i].lat_fin),
            lng: parseFloat(this.pathPoli[i].lon_fin),
          });
        }
      }
    },

    updateEdited: function(mvcPath) {
      this.mvcPath = mvcPath;
    },

    addPath: function() {
      var bounds = this.$refs.map.$mapObject.getBounds();
      var northEast = bounds.getNorthEast();
      var southWest = bounds.getSouthWest();
      var center = bounds.getCenter();
      var degree = this.path.length + 1;
      var f = Math.pow(0.66, degree);

      this.path = [
        {
          lng: center.lng(),
          lat: parseFloat((1 - f) * center.lat() + f * northEast.lat()),
        },
        {
          lng: parseFloat((1 - f) * center.lng() + f * southWest.lng()),
          lat: parseFloat(center.lat()),
        },
      ];
    },

    async editPolilinea(event) {
      if (event.edge >= 0 && this.accion == true) {
        this.form.km_max = await this.pathPoli[event.edge].km_max;
        this.form.observacion = await this.pathPoli[event.edge].observacion;
        this.infoWindowPos = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
        this.infoWinOpen = true;
        this.edge = event.edge;
        this.activo = event.edge;
      }
    },

    async editPoli(poli) {
      this.form.km_max = await this.pathPoli[poli.orden].km_max;
      this.form.observacion = await this.pathPoli[poli.orden].observacion;

      let cordLat = (parseFloat(poli.lat_ini) + parseFloat(poli.lat_fin)) / 2;
      let cordLng = (parseFloat(poli.lon_ini) + parseFloat(poli.lon_fin)) / 2;

      this.infoWindowPos = {
        lat: cordLat,
        lng: cordLng,
      };
      this.infoWinOpen = true;
      this.edge = poli.orden;
      this.activo = poli.orden;
    },

    async addKm() {
      for (let i = 0; i < this.pathPoli.length; i++) {
        if (i == this.edge) {
          this.pathPoli[i].km_max = await this.form.km_max;
          this.pathPoli[i].observacion = await this.form.observacion;
        }
      }
      this.infoWinOpen = false;
    },

    async addMarkers(event) {
      if (this.accion == false) {
        const path = {
          lat: parseFloat(event.latLng.lat()),
          lng: parseFloat(event.latLng.lng()),
        };

        this.markers.push({
          position: path,
        });

        if (this.markers.length > 0) {
          let markers = this.markers.slice(-1);
          this.path.push({
            lng: markers[0].position.lng,
            lat: markers[0].position.lat,
          });
        }

        if (this.markers.length > 2) {
          this.markers.splice(1, 2);
        }
      }
    },

    savePolilineas() {
      this.cargando = true;
      axios
        .put("/api/admin/rutas/assingOrRevokePolilineas", {
          ruta_id: this.$parent.id,
          arreglo: this.pathPoli,
          lineasEliminar: this.lineasEliminar,
        })
        .then((response) => {
          this.cargando = false;
          this.$swal({
            icon: "success",
            title: "Se actualizaron los datos de la ruta exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    handleClickForDelete($event) {
      if ($event.vertex !== undefined) {
        this.path.splice($event.vertex, 1);
      }
    },

    renderizarRuta() {
      if (document.getElementById("accion").checked) {
        let primero = this.path[0];
        let ultimo = this.path[this.path.length - 1];

        this.markers = [];
        const markerStart = {
          lat: parseFloat(primero.lat),
          lng: parseFloat(primero.lng),
        };

        const markerEnd = {
          lat: parseFloat(ultimo.lat),
          lng: parseFloat(ultimo.lng),
        };

        this.markers.push(
          {
            position: markerStart,
            icon: "/img/icon_punto_g_ok.png",
          },
          {
            position: markerEnd,
            icon: "/img/icon_punto_g.png",
          }
        );
        let orden = 0;
        let tam = this.path.length;
        for (let i = 0; i < this.path.length; i++) {
          if (i < tam - 1) {
            if (i > 0) {
              orden = orden + 1;
            }
            this.pathPoli.push({
              id: null,
              ruta_id: this.$parent.id,
              lat_ini: this.path[i].lat,
              lon_ini: this.path[i].lng,
              lat_fin: this.path[i + 1].lat,
              lon_fin: this.path[i + 1].lng,
              km_max: 50,
              orden: orden,
            });
          }
        }

        let puntos_ant = this.$parent.form.ruta_polilineas;
        for (let i = 0; i < this.pathPoli.length; i++) {
          const element = this.pathPoli[i];
          let lineaant = puntos_ant.find(
            (l) =>
              parseFloat(l.lat_ini) === parseFloat(element.lat_ini) &&
              parseFloat(l.lon_ini) === parseFloat(element.lon_ini) &&
              parseFloat(l.lat_fin) === parseFloat(element.lat_fin) &&
              parseFloat(l.lon_fin) === parseFloat(element.lon_fin)
          );

          if (lineaant) {
            this.pathPoli[i].id = lineaant.id;
            this.pathPoli[i].km_max = lineaant.km_max;
            this.pathPoli[i].observacion = lineaant.observacion;
          }
        }

        for (let i = 0; i < puntos_ant.length; i++) {
          const element = puntos_ant[i];
          let lineanew = this.pathPoli.find(
            (l) =>
              parseFloat(l.lat_ini) === parseFloat(element.lat_ini) &&
              parseFloat(l.lon_ini) === parseFloat(element.lon_ini) &&
              parseFloat(l.lat_fin) === parseFloat(element.lat_fin) &&
              parseFloat(l.lon_fin) === parseFloat(element.lon_fin)
          );

          if (lineanew === undefined) {
            this.lineasEliminar.push(element.id);
          }
        }

        // RECORRER PUNTOS BASE QUE BIENEN DE PADRE Y SI COINCIDE LAS DOS
        //LAT Y LAS DOS LON SE LE DEBE ASIGNAR ID PARA LA EDICION DEL ORDEN Y SE DEBE DEJAR LOS MISMOS KMS Y OBS
      } else {
        this.pathPoli = [];
        this.lineasEliminar = [];
      }
    },

    limpiarMapa() {
      this.path = [];
      this.markers = [];
      this.accion = false;
    },
  },

  mounted() {
    this.init();
    this.center = {
      lat: parseFloat(this.$parent.form.ruta_polilineas[0].lat_ini),
      lng: parseFloat(this.$parent.form.ruta_polilineas[0].lon_ini),
    };
  },
};
</script>
